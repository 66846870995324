export const conversionRate = 15.00;
export const convertToGHS = (usdPrice) => parseFloat(usdPrice * conversionRate);


// EV Cars List
export const vehicles = [
    // Existing cars updated with new prices
    { id: 1, name: "Nami Air 430km", priceUSD: 25000, consumption: 15, images: ["/images/nami-air-cover.jpg"], specs: ["430 km range", "Electric Motor", "Power: 110 kW", "Fast Charging Support"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 2, name: "Nano Elegant 330", priceUSD: 20000, consumption: 14, images: ["/images/nano-elegant-cover.jpg"], specs: ["330 km range", "Electric Motor", "Power: 90 kW", "Display Screen"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 3, name: "Nano Standard 330", priceUSD: 18500, consumption: 13, images: ["/images/nano-standard-cover.jpg"], specs: ["330 km range", "Electric Motor", "Power: 85 kW", "Basic Features"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 4, name: "Neta Aya 2024", priceUSD: 22500, consumption: 16, images: ["/images/neta-aya-cover.jpg"], specs: ["480 km range", "Electric Motor", "Power: 170 kW", "0-100 km/h in 6.5s"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 5, name: "Neta X 500", priceUSD: 32900, consumption: 15, images: ["/images/neta-x-cover.jpg"], specs: ["500 km range", "Electric Motor", "Power: 180 kW", "Luxury Interior"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 6, name: "XPeng G9 702 Pro", priceUSD: 63500, consumption: 17, images: ["/images/xpeng-g9-cover.jpg"], specs: ["702 km range", "Electric Motor", "Power: 200 kW", "High Performance"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 7, name: "BYD Sealion 07", priceUSD: 52900, consumption: 16, images: ["/images/byd-sealion-cover.jpg"], specs: ["480 km range", "Electric Motor", "Power: 190 kW", "Advanced Safety Systems"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 8, name: "Radar Pickup RD6 2WD", priceUSD: 37514, consumption: 19, images: ["/images/radar-pickup-cover.jpg"], specs: ["400 km range", "Electric Motor", "Power: 220 kW", "Off-road Capability"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 9, name: "Radar Pickup RD6 4WD", priceUSD: 54816, consumption: 21, images: ["/images/radar-4wd-cover.jpg"], specs: ["420 km range", "Electric Motor", "Power: 240 kW", "Enhanced Off-road"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 10, name: "BYD Yuan Plus", priceUSD: 31460, consumption: 15, images: ["/images/byd-yuan-cover.jpg"], specs: ["450 km range", "Electric Motor", "Power: 160 kW", "Compact Design"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 11, name: "BYD Song Plus", priceUSD: 40944, consumption: 17, images: ["/images/byd-song-plus-cover.jpg"], specs: ["460 km range", "Electric Motor", "Power: 170 kW", "Advanced Features"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 12, name: "BYD Han", priceUSD: 44950, consumption: 18, images: ["/images/byd-han-cover.jpg"], specs: ["500 km range", "Electric Motor", "Power: 180 kW", "Premium Features"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 13, name: "BYD Tang", priceUSD: 55356, consumption: 20, images: ["/images/byd-tang-cover.jpg"], specs: ["500 km range", "Electric Motor", "Power: 200 kW", "Luxury Interior"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 14, name: "BYD E 405km Travel Edition", priceUSD: 25040, consumption: 15, images: ["/images/byd-e-travel-cover.jpg"], specs: ["405 km range", "Electric Motor", "Power: 150 kW", "Travel Ready"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 15, name: "BYD Seagull", priceUSD: 20938, consumption: 12, images: ["/images/byd-seagull-cover.jpg"], specs: ["360 km range", "Electric Motor", "Power: 120 kW", "Affordable EV"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 16, name: "Nano 330 Plus Smart", priceUSD: 24183, consumption: 14, images: ["/images/nano-330-smart-cover.jpg"], specs: ["360 km range", "Electric Motor", "Power: 140 kW", "Smart Features"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 17, name: "Nano 330 Plus", priceUSD: 22553, consumption: 13, images: ["/images/nano-330-plus-cover.jpg"], specs: ["330 km range", "Electric Motor", "Power: 130 kW", "Enhanced Features"], get priceGHS() { return convertToGHS(this.priceUSD) } },

    { id: 18, name: "Nami Air Plus 430", priceUSD: 26300, consumption: 15, images: ["/images/nami-air-plus-cover.jpg"], specs: ["430km range", "Electric Motor", "Power: 110 kW", "Fast Charging Support"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 19, name: "Neta Aya 401", priceUSD: 22500, consumption: 15, images: ["/images/neta-aya-401-cover.jpg"], specs: ["401 km range", "Electric Motor", "Power: 150 kW", "Efficient Energy Use"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 20, name: "XPeng X9", priceUSD: 83371, consumption: 20, images: ["/images/xpeng-x9-cover.jpg"], specs: ["600 km range", "Electric Motor", "Power: 250 kW", "Luxury Interior"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 21, name: "XPeng G9 702 Max", priceUSD: 69921, consumption: 18, images: ["/images/xpeng-g9-max-cover.jpg"], specs: ["702 km range", "Electric Motor", "Power: 200 kW", "Advanced Autonomous Features"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 22, name: "Fangcheng Bao 5 Yun Deluxe", priceUSD: 65474, consumption: 22, images: ["/images/fangcheng-deluxe-cover.jpg"], specs: ["550 km range", "Electric Motor", "Power: 220 kW", "Luxury Features"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 23, name: "VW ID.4 Crozz Pro", priceUSD: 49214, consumption: 17, images: ["/images/vw-id4-crozz-cover.jpg"], specs: ["500 km range", "Electric Motor", "Power: 180 kW", "Advanced Connectivity"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 24, name: "ID6 Crozz Pure Prime", priceUSD: 61454, consumption: 18, images: ["/images/id6-crozz-prime-cover.jpg"], specs: ["600 km range", "Electric Motor", "Power: 200 kW", "Luxury Interior"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 25, name: "Neta X 500 Pro (2025)", priceUSD: 34174, consumption: 14, images: ["/images/neta-x-500-pro-cover.jpg"], specs: ["500 km range", "Electric Motor", "Power: 180 kW", "Next-gen Features"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 26, name: "BYD Song", priceUSD: 52491, consumption: 17, images: ["/images/byd-song-cover.jpg"], specs: ["480 km range", "Electric Motor", "Power: 190 kW", "High Performance"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 27, name: "Yanguan U8", priceUSD: 256000, consumption: 30, images: ["/images/yanguan-u8-cover.jpg"], specs: ["800 km range", "Electric Motor", "Power: 300 kW", "Ultimate Performance"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 28, name: "BYD Yuan Up", priceUSD: 28994, consumption: 14, images: ["/images/byd-yuan-up-cover.jpg"], specs: ["420 km range", "Electric Motor", "Power: 150 kW", "Compact SUV"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 29, name: "BYD Sealion 07 Max (AWD)", priceUSD: 58200, consumption: 16, images: ["/images/byd-sealion-max-cover.jpg"], specs: ["500 km range", "Electric Motor", "Power: 200 kW", "AWD Performance"], get priceGHS() { return convertToGHS(this.priceUSD) } },
    { id: 30, name: "Nami Air 330", priceUSD: 22328, consumption: 12, images: ["/images/nami-air-330-cover.jpg"], specs: ["330 km range", "Electric Motor", "Power: 100 kW", "High Efficiency"], get priceGHS() { return convertToGHS(this.priceUSD) } }
];

// ICE Cars List
export const iceModels = [
    {
        id: 1,
        name: "Mitsubishi L200 2024",
        priceUSD: 36700,
        priceGHS: 605550,
        fuelEfficiency: 8.5, // L/100km
        specs: ["600 km range", "2.4L Diesel Engine", "150 kW Power", "Fuel Consumption: 8.5 L/100km", "Top Speed: 170 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 2,
        name: "Suzuki Swift",
        priceUSD: 18000,
        priceGHS: 297000,
        fuelEfficiency: 7.0, // L/100km
        specs: ["430 km range", "1.2L Petrol Engine", "70 kW Power", "Fuel Consumption: 7.0 L/100km", "Top Speed: 170 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 3,
        name: "Suzuki S-Presso",
        priceUSD: 13400,
        priceGHS: 221100,
        fuelEfficiency: 6.2, // L/100km
        specs: ["400 km range", "1.0L Petrol Engine", "50 kW Power", "Fuel Consumption: 6.2 L/100km", "Top Speed: 155 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 4,
        name: "Suzuki Baleno",
        priceUSD: 22000,
        priceGHS: 363000,
        fuelEfficiency: 7.1, // L/100km
        specs: ["460 km range", "1.2L Petrol Engine", "80 kW Power", "Fuel Consumption: 7.1 L/100km", "Top Speed: 165 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 5,
        name: "Hyundai Sonata",
        priceUSD: 37000,
        priceGHS: 610500,
        fuelEfficiency: 8.0, // L/100km
        specs: ["550 km range", "2.0L Petrol Engine", "105 kW Power", "Fuel Consumption: 8.0 L/100km", "Top Speed: 190 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 6,
        name: "Hyundai Accent",
        priceUSD: 22500,
        priceGHS: 363000,
        fuelEfficiency: 7.0, // L/100km
        specs: ["480 km range", "1.6L Petrol Engine", "90 kW Power", "Fuel Consumption: 7.0 L/100km", "Top Speed: 175 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 7,
        name: "Toyota Hilux - Automatic",
        priceUSD: (890000 / conversionRate).toFixed(2), // GHS to USD
        priceGHS: 890000,
        fuelEfficiency: 9.0, // L/100km
        specs: ["550 km range", "2.8L Diesel Engine", "130 kW Power", "Fuel Consumption: 9.0 L/100km", "Top Speed: 165 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 8,
        name: "Toyota Hilux - Manual",
        priceUSD: (780000 / 16.5).toFixed(2), // GHS to USD
        priceGHS: 780000,
        fuelEfficiency: 9.0, // L/100km
        specs: ["550 km range", "2.8L Diesel Engine", "130 kW Power", "Fuel Consumption: 9.0 L/100km", "Top Speed: 165 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 9,
        name: "Toyota Corolla - 1.6L",
        priceUSD: (695000 / 16.5).toFixed(2), // GHS to USD
        priceGHS: 695000,
        fuelEfficiency: 7.5, // L/100km
        specs: ["600 km range", "1.6L Petrol Engine", "100 kW Power", "Fuel Consumption: 7.5 L/100km", "Top Speed: 180 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 10,
        name: "Toyota Corolla - 1.8L",
        priceUSD: (787000 / 16.5).toFixed(2), // GHS to USD
        priceGHS: 787000,
        fuelEfficiency: 7.2, // L/100km
        specs: ["610 km range", "1.8L Petrol Engine", "105 kW Power", "Fuel Consumption: 7.2 L/100km", "Top Speed: 185 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 11,
        name: "Honda CRV",
        priceUSD: 60000,
        priceGHS: 1002000,
        fuelEfficiency: 7.8, // L/100km
        specs: ["500 km range", "2.0L Petrol Engine", "110 kW Power", "Fuel Consumption: 7.8 L/100km", "Top Speed: 180 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 12,
        name: "Honda Accord",
        priceUSD: 59000,
        priceGHS: 985300,
        fuelEfficiency: 7.5, // L/100km
        specs: ["600 km range", "1.5L Petrol Engine", "120 kW Power", "Fuel Consumption: 7.5 L/100km", "Top Speed: 200 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 13,
        name: "Honda Civic",
        priceUSD: 44000,
        priceGHS: 734800,
        fuelEfficiency: 7.3, // L/100km
        specs: ["520 km range", "1.5L Petrol Engine", "100 kW Power", "Fuel Consumption: 7.3 L/100km", "Top Speed: 185 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 14,
        name: "Kia Morning - Full Option",
        priceUSD: 16500,
        priceGHS: 272250,
        fuelEfficiency: 6.5, // L/100km
        specs: ["400 km range", "1.0L Petrol Engine", "60 kW Power", "Fuel Consumption: 6.5 L/100km", "Top Speed: 160 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 15,
        name: "Kia Morning - Standard",
        priceUSD: 14900,
        priceGHS: 245850,
        fuelEfficiency: 6.5, // L/100km
        specs: ["400 km range", "1.0L Petrol Engine", "60 kW Power", "Fuel Consumption: 6.5 L/100km", "Top Speed: 160 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    },
    {
        id: 16,
        name: "Kia Pegas",
        priceUSD: 17980,
        priceGHS: 296670,
        fuelEfficiency: 6.9, // L/100km
        specs: ["450 km range", "1.4L Petrol Engine", "85 kW Power", "Fuel Consumption: 6.9 L/100km", "Top Speed: 170 km/h"],
        // get priceGHS() {
        //     return convertToGHS(this.priceUSD);
        // }
    }
];

